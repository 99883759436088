import React from 'react';
import MarketingPerformance from '../shared/performance/MarketingPerformance';
import './OverviewPerformance.scss';
import { ABOUT_RESEARCH_URL } from '../../shared/constants/urls';
import { OverviewPerformanceText } from './enum/OverviewPerformanceText';

const OverviewPerformance: React.FC = () => {
  return (
    <div className="OverviewPerformance">
      <MarketingPerformance />
    </div>
  );
};

export default OverviewPerformance;
